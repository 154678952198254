import React, { useState, useEffect } from "react";
import classes from "./EditForm.module.css";
import Card from "@mui/material/Card";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Container, Row, Col, CardFooter } from "reactstrap";
import Input from "../../components/Input/Input";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../variables/api";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const EditForm = (props) => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    FormArr: [],
    description: "",
  });
  const [submit, setSubmit] = useState(false);
  const id = useParams();
  const handleClickBack = () => {
    navigate("/");
  };

  useEffect(() => {
    axios.post(`${BASE_URL}/surveys/getsurvey.php`, id).then((res) => {
      setFormData((prevstate) => ({
        ...prevstate,
        name: res.data.data.name,
        FormArr: res.data.data.questions,
        description: res.data.data.description,
      }));
    });
  }, []);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    if (name === "required") {
      setFormData((prevstate) => ({ ...prevstate, required: checked }));
    } else {
      setFormData((prevstate) => ({ ...prevstate, [name]: value }));
    }
  };
  const handleChangeDesc = (event) => {
    setFormData((prevstate) => ({ ...prevstate, description: event }));
  };
  const submitHandler = (e) => {
    e.preventDefault();
    Swal.showLoading();
    axios
      .post(`${BASE_URL}/surveys/addsurvey.php`, { formData: formData, id: id })
      .then((res) => {
        Swal.fire("Survey was Updated Successfully", "", "success").then(() => {
          navigate("/");
        });
      })
      .finally(() => setSubmit(!submit));
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const handleChangeSelect = (event, id) => {
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        question.type = event;
        console.log(question);
        if (event.value == "linescale") {
          let options = [];
          for (let index = 1; index <= 10; index++) {
            options.push({
              id: "option-" + index,
              value: index,
              response: "",
            });
          }
          question.options = options;
          question.startnumber = { value: "1", label: "1" };
          question.endnumber = { value: "10", label: "10" };

          setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
        } else {
          if (question.options?.length === 0) {
            question.options = [];
          }
        }
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleChangeDynamic = (event, id) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        if ("startnumber" === name) {
          question.startnumber = value;
        }
        if ("endnumber" === name) {
          question.endnumber = value;
        }
        if ("required" === name) {
          question.required = checked;
        }
        if ("Question" === name) {
          question.Question = value;
        }
        if ("type" === name) {
          question.type = value;
        }
        if ("description" === name) {
          question.description = value;
        }
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleChangeSelectStart = (event, id) => {
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        question.startnumber = event;
        let options = [];
        for (
          let index = +question.startnumber?.value;
          index < +question.endnumber?.value;
          index++
        ) {
          options.push({
            id: "option-" + index,
            value: index,
            response: "",
          });
        }
        question.options = options;
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleChangeSelectEnd = (event, id) => {
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        question.endnumber = event;
        let options = [];

        for (
          let index = +question.startnumber?.value;
          index <= +question.endnumber.value;
          index++
        ) {
          options.push({
            id: "option-" + index,
            value: index,
            response: "",
          });
        }
        question.options = options;
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  let count = 0;
  const handleChangeDynamicOptions = (event, id, optionNumber) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        if ("options" === name) {
          question.options.map((option) => {
            if (option.id === `option-${optionNumber}`) {
              option.value = value;
            }
          });
        }
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const deleteQuestionHandeler = (id) => {
    let FormArrDeleteCopy = [...formData.FormArr];
    let index = FormArrDeleteCopy.findIndex((question) => question.id === id);
    FormArrDeleteCopy.splice(index, 1);
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrDeleteCopy }));
  };
  const deleteOptionHandeler = (id, optionid) => {
    let FormArrDeleteCopy = [...formData.FormArr];
    FormArrDeleteCopy.map((question) => {
      if (question.id === id) {
        let index = question.options.findIndex(
          (option) => option.id === `option-${optionid}`
        );
        question.options.splice(index, 1);
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrDeleteCopy }));
  };
  const AddOptionHandeler = (e, id) => {
    e.preventDefault();
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        let lenght = question.options.length + 1;
        question.options.push({ id: `option-${lenght}`, value: "" });
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleClick = () => {
    setFormData((prevstate) => ({
      ...prevstate,
      FormArr: formData.FormArr.concat({
        id: makeid(10),
        Question: "",
        type: "",
        description: "",
        response: "",
        options: [{ id: `option-1`, value: "" }],
      }),
    }));
  };
  let numbers = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  let options = [
    { value: "paragraph", label: "Short Answer" },
    { value: "checkbox", label: "Multiple Choice (Multiple selection)" },
    { value: "date", label: "Date" },
    { value: "email", label: "Email" },
    { value: "file", label: "File" },
    { value: "radio", label: "Multiple Choice (Single selection)" },
    { value: "linescale", label: "Line Scale" },
  ];

  let temparr = ["Views", "Responeses Recieved", "Individuals", "Status"];
  let temparrFormNumbers = ["0", "0", "0"];
  return (
    <Container className="container">
      <Link className="btn btn-secondary mb-3" to="/">
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
      </Link>

      <form onSubmit={submitHandler}>
        <Row style={{ marginTop: "10%" }} className="row">
          <Col className="col-md-9">
            <Input
              label="Enter Form Name"
              value={formData.name}
              type="text"
              name="name"
              placeholder="Write New Form's Name"
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="row">
          <Col className="col-md-9">
            <SunEditor
              setContents={`${formData.description}`}
              name="description"
              onChange={handleChangeDesc}
              setOptions={{
                defaultStyle: "font-family: arial; font-size: 20px;",
                height: 200,
                buttonList: buttonList.complex,
              }}
            />
          </Col>
        </Row>
        <Row>
          <h2>{formData.name}</h2>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col className="col-md-9">
            <div>
              <h2 className={classes.h2}>Questions</h2>
              <div>
                {formData.FormArr.map((t) => {
                  console.log();
                  return (
                    <Card
                      style={{
                        minHeight: "150px",
                        marginTop: "20px",
                        overflow: "visible",
                      }}
                      key={t.id}
                    >
                      <Row>
                        <Col>
                          <Input
                            type="text"
                            name="Question"
                            placeholder="Write Your Question"
                            value={t.Question}
                            onChange={(event) => {
                              handleChangeDynamic(event, t.id);
                            }}
                          />

                          {t.type.value === "select" ||
                          t.type.value === "checkbox" ||
                          t.type.value === "radio" ? (
                            <div style={{ marginBottom: "-100px" }}>
                              {" "}
                              {t.options.map((option, index) => {
                                return (
                                  <div
                                    key={option.id}
                                    style={{
                                      display: "flex",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Input
                                      id={option.id}
                                      type="text"
                                      name="options"
                                      placeholder={`add option ${index + 1}`}
                                      value={option.value}
                                      required
                                      onChange={(event) => {
                                        handleChangeDynamicOptions(
                                          event,
                                          t.id,
                                          `${index + 1}`
                                        );
                                      }}
                                    />
                                    <DeleteIcon
                                      className={classes.trash}
                                      style={{
                                        marginTop: "7%",
                                        color: "red",
                                        marginLeft: "1%",
                                      }}
                                      onClick={() => {
                                        deleteOptionHandeler(
                                          t.id,
                                          `${index + 1}`
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                              <button
                                style={{
                                  marginBottom: "25%",
                                  marginLeft: "2%",
                                }}
                                onClick={(e) => AddOptionHandeler(e, t.id)}
                                className="btn btn-primary"
                              >
                                Add Option
                              </button>
                            </div>
                          ) : null}
                          {t.type.value === "linescale" ? (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "5px",
                                  justifyContent: "space-evenly",
                                  flex: "wrap",
                                }}
                              >
                                <Input
                                  id="startnumber"
                                  options={numbers}
                                  type="select-1"
                                  name="startnumber"
                                  placeholder={`Start Range`}
                                  value={t.startnumber}
                                  required={true}
                                  onChange={(event) => {
                                    handleChangeSelectStart(event, t.id);
                                  }}
                                />
                                <Input
                                  id="endnumber"
                                  options={numbers}
                                  type="select-1"
                                  name="endnumber"
                                  placeholder={`End Range`}
                                  value={t.endnumber}
                                  required={true}
                                  onChange={(event) => {
                                    handleChangeSelectEnd(event, t.id);
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </Col>
                        <Col>
                          <Input
                            type="select-1"
                            style={{ zIndex: 10000 }}
                            className={classes.select}
                            options={options}
                            required
                            value={t.type}
                            onChange={(event) => {
                              handleChangeSelect(event, t.id);
                            }}
                          />
                        </Col>
                      </Row>
                      <CardFooter
                        style={{ display: "flex", justifyContent: "right" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <DeleteIcon
                            className={classes.trash}
                            onClick={() => deleteQuestionHandeler(t.id)}
                          />
                          <p className={classes.p}>Optional</p>
                          <Switch
                            checked={t.required}
                            name="required"
                            onChange={(event) =>
                              handleChangeDynamic(event, t.id)
                            }
                          />
                          <p className={classes.p}>Required</p>
                        </div>
                      </CardFooter>
                    </Card>
                  );
                })}
                <Card className={classes.Add} onClick={handleClick}>
                  <AddCircleIcon style={{ color: "#3278bd" }} />
                </Card>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "left" }}>
          <button
            style={{ margin: "10px" }}
            type="submit"
            className="btn btn-primary"
          >
            Save
          </button>
        </Row>
      </form>
    </Container>
  );
};
export default EditForm;
