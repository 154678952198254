import React, { Fragment, useEffect, useState } from "react";
import classes from "./ViewResponse.module.css";
import Card from "@mui/material/Card";
import Input from "../../../components/Input/Input";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { BASE_URL } from "../../../variables/api";
import useCheckSuperAdmin from "../../../hooks/useCheckSuperAdmin";

const MyForms = (props) => {
  const checkIfAdmin = useCheckSuperAdmin();

  let emaildomain = localStorage.getItem("emaildomain")
 
  if(checkIfAdmin){
    let selected_school  = localStorage.getItem("selected_school")
    emaildomain =JSON.parse(selected_school)?.value
  }
  
  let navigate = useNavigate();
  const { id } = useParams();
  console.log("iam id ", id);
  const [formData, setFormData] = useState({
    name: "",
    FormArr: [],
    description: "",
  });
  const [option, setOptions] = useState();
  const [add, setAdd] = useState(false);
  const handleClickDetailedView = (questionId, questionName) => {
    navigate(`/details-question/${id}/${questionId}/${questionName}`);
  };

  useEffect(() => {
    axios
      .post(`${BASE_URL}/surveys/getresponse.php`, { id: id })
      .then((res) => {
        setFormData((prevstate) => ({
          ...prevstate,
          name: res.data.data.survey?.name,
          FormArr: res.data.data.response?.responses,
          description: res.data.data.survey?.description,
        }));

        const options = res.data.data.response?.responses[0]?.options?.map(
          (option) => {
            return { value: option.value, label: `${option.value} ` };
          }
        );
        setOptions(options);
      });
  }, [add]);
  const handleChange = (event, id) => {
    const name = event.target.name;
    const value = event.target.value;
    let FormArrCopy = [...formData.FormArr];

    FormArrCopy.map((question) => {
      if (question.id === id) {
        question.response = value;
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleChangeselect = (event, id) => {
    let FormArrCopy = [...formData.FormArr];

    FormArrCopy.map((question) => {
      if (question.id === id) {
        question.response = event;
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleChangeDynamicOptions = (event, id, optionNumber) => {
    const name = event.target.name;
    const checked = event.target.checked;
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question, index) => {
      if (question.id === id) {
        question.options.map((option) => {
          if (option.id === `option-${optionNumber}`) {
            option.response = checked;
          }
        });
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/surveys/addresponse.php`, {
        formData: formData,
        id: id,
      })
      .then((res) => {
        Swal.fire("Response submitted Successfully", "", "success");
      })
      .finally(() => setAdd(!add));
  };

  return (
    <Fragment>
      <div
        style={{ textAlign: "center", marginTop: "50px" }}
        className={classes.minus}
      >
        <img src={`/assets/${emaildomain}/logo.png`} className={classes.img} alt="logo" />
      </div>
      <h2 className={classes.h2} style={{ textAlign: "center" }}>
        {formData.name} Form
      </h2>
      <h2 className={classes.description} style={{ textAlign: "center" }}>
        <div dangerouslySetInnerHTML={{ __html: formData?.description }}></div>
      </h2>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Card className="col-md-9">
          <form onSubmit={submitHandler}>
            {formData.FormArr.map((t, i) => {
              if (t.type.value === "checkbox" || t.type.value === "radio") {
                return (
                  <>
                    Question ({i + 1})
                    <div
                      key={`checkbox-${i}`}
                      style={{
                        marginLeft: "5%",
                        padding: "5px",
                        border: "1px solid",
                        marginBottom: "2%",
                      }}
                    >
                      <div className="mb-2">{`  ${t.Question} `}</div>

                      <div style={{ textAlign: "center" }}>
                        {t.options?.map((option, index) => {
                          return (
                            <div
                              className="form-check-inline"
                              key={`checkbox-options-${i}-${index}`}
                            >
                              <Input
                                className="form-check-input"
                                disabled
                                id={option.id}
                                name={t.Question}
                                type={t.type.value}
                                checked={option.response ? true : false}
                                description={t.description}
                                onChange={(event) => {
                                  handleChangeDynamicOptions(
                                    event,
                                    t.id,
                                    `${index + 1}`,
                                    t.Question
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={option.id}
                              >
                                {option.value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              } else if (t.type.value === "linescale") {
                return (
                  <>
                    Question ({i + 1})
                    <div
                      key={`linescale-${i}`}
                      style={{
                        marginLeft: "5%",
                        padding: "5px",
                        border: "1px solid",
                        marginBottom: "2%",
                      }}
                    >
                      <div className="mb-2">{` ${t.Question}`}</div>

                      <div style={{ textAlign: "center" }}>
                        {t.options.map((option, index) => {
                          return (
                            <div
                              className="form-check-inline"
                              key={`linescale-options-${i}-${index}`}
                            >
                              <Input
                                id={option.value}
                                className="form-check-input"
                                name={t.Question}
                                type="radio"
                                disabled
                                required={t.required}
                                description={t.description}
                                value={option.value}
                                checked={option.response}
                                onChange={(event) => {
                                  handleChangeDynamicOptions(
                                    event,
                                    t.id,
                                    `${index + 1}`,
                                    t.Question
                                  );
                                }}
                              />
                              <label
                                htmlFor={option.value}
                                className="form-check-label"
                              >
                                {option.value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              } else if (t.type.value === "select") {
                return (
                  <>
                    Question ({i + 1})
                    <div
                      key={`select-${i}`}
                      style={{
                        marginLeft: "5%",
                        padding: "5px",
                        border: "1px solid",
                        marginBottom: "2%",
                      }}
                    >
                      <div className="mb-2">{` ${t.Question}`}</div>
                      <Input
                        type={t.type.value}
                        value={t.response}
                        required={true}
                        options={option}
                        onChange={(event) => {
                          handleChangeselect(event, t.id);
                        }}
                      />
                    </div>
                  </>
                );
              } else if (t.type.value === "file") {
                if (t.response.length) {
                  return (
                    <>
                      Question ({i + 1})
                      <div
                        key={`file-${i}`}
                        style={{
                          marginLeft: "5%",
                          padding: "5px",
                          border: "1px solid",
                          marginBottom: "2%",
                        }}
                      >
                        <div className="mb-2">{` ${t.Question}`}</div>
                        <a
                          href={` https://drive.google.com/uc?id=${
                            JSON.parse(t.response).driveid
                          }&export=download`}
                        >
                          {" "}
                          {JSON.parse(t.response).fileName}
                        </a>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      Question ({i + 1})
                      <div
                        key={`file-${i}`}
                        style={{
                          marginLeft: "5%",
                          padding: "5px",
                          border: "1px solid",
                          marginBottom: "2%",
                        }}
                      >
                        <div className="mb-2">{` ${t.Question}`}</div>

                        {"No file Upoaded"}
                      </div>
                    </>
                  );
                }
              } else {
                return (
                  <>
                    Question ({i + 1})
                    <div
                      key={`other-${i}`}
                      style={{
                        marginLeft: "5%",
                        padding: "5px",
                        border: "1px solid",
                        marginBottom: "2%",
                      }}
                    >
                      <div className="mb-2">{` ${t.Question}`}</div>
                      <Input
                        type={t.type.value}
                        required={true}
                        value={t.response}
                        onChange={(event) => {
                          handleChange(event, t.id);
                        }}
                      />
                    </div>
                  </>
                );
              }
            })}
          </form>
        </Card>
        <Card style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Input
              className={classes.range}
              orient="vertical"
              type="range"
              name="range"
              defaultValue={25}
            />
          </div>
          <div>
            {formData.FormArr.map((question, index) => {
              return (
                <div
                  className={classes.question}
                  key={`${index}`}
                  onClick={() =>
                    handleClickDetailedView(question.id, question.Question)
                  }
                >
                  {`Question ${index + 1}`}
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    </Fragment>
  );
};
export default MyForms;
